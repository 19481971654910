import { AxiosError } from "axios";
import { useState, useEffect } from "react";
import { useEventCallback } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { GridActionsCellItem, GridRowId } from "@mui/x-data-grid";
import { GridColDef, GridRowsProp } from "@mui/x-data-grid/models";

import api from "../../services/api";
import Grid from "../../components/grid";
import { ApiError } from "../../services/tipos";
import Button from "../../rxlib/components/buttons/button";
import InputLabel from "../../rxlib/components/input-label";
import { RxlibLayout } from "../../rxlib/components/layout/rxlib-Layout";
import { ModalWarning } from "../../rxlib/components/modal/modal-warning";
import Breadcrumb, { BreadcrumbItem } from "../../rxlib/components/breadcrumb";

import {
    tratarErroApi,
    obterDataAtual,
    formatarPorTipo,
    obterDataAtualMenosDias,
} from "../../rxlib/services/utilitarios";

interface Filtros {
    dataInicial: string;
    dataFinal: string;
}

function FaturaVencimento() {
    const [dados, setDados] = useState<GridRowsProp>([]);
    const [carregando, setCarregando] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [messageWarning, setMessageWarning] = useState<string[]>([]);

    const diasPeriodoInicialFiltro = 7;
    const dataInicialString = obterDataAtualMenosDias(diasPeriodoInicialFiltro);
    const dataFinalString = obterDataAtual();

    const [filtros, setFiltros] = useState<Filtros>({
        dataInicial: dataInicialString,
        dataFinal: dataFinalString,
    });

    const { register, handleSubmit } = useForm<Filtros>();

    const handleHide = () => setShowWarning(false);

    const breadcrumbs: BreadcrumbItem[] = [
        { texto: "Home", link: "/home" },
        { texto: "Faturas e vencimentos", link: "" },
    ];

    const colunas: GridColDef[] = [
        {
            width: 30,
            minWidth: 30,
            type: "actions",
            field: "actions",
            headerName: "Opções",
            cellClassName: "cargas-sac-grid-cell",
            headerClassName: "cargas-sac-grid-header cargas-sac-grid-header-icon",
            getActions: (params) => [
                <GridActionsCellItem
                    showInMenu={true}
                    label="Download Fatura"
                    icon={<i className="fas fa-file-pdf" />}
                    onClick={handleDownloadFatura(params.id)} />,
            ],
            renderHeader: () => (
                <i className="fa-solid fa-gear" />
            ),
        },
        { field: "codigo", headerName: "Código da fatura", width: 140, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell" },
        { field: "emissao", headerName: "Data de emissão", width: 140, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell", type: "date", valueFormatter: ({ value }) => value && formatarPorTipo("date", value) },
        { field: "vencimento", headerName: "Data de vencimento", width: 170, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell", type: "date", valueFormatter: ({ value }) => value && formatarPorTipo("date", value) },
        { field: "pagamento", headerName: "Data de pagamento", width: 160, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell", type: "date", valueFormatter: ({ value }) => value && formatarPorTipo("date", value) },
        { field: "valor", headerName: "Valor da fatura", width: 130, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell", type: "number", valueFormatter: ({ value }) => formatarPorTipo("currency", value) },
        { field: "desconto", headerName: "Desconto na fatura", width: 160, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell", type: "number", valueFormatter: ({ value }) => formatarPorTipo("currency", value) },
        { field: "acrescimo", headerName: "Acréscimo", width: 90, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell", type: "number", valueFormatter: ({ value }) => formatarPorTipo("currency", value) },
        { field: "valorrecebido", headerName: "Valor recebido", width: 130, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell", type: "number", valueFormatter: ({ value }) => formatarPorTipo("currency", value) },
    ];

    useEffect(() => {
        if ((filtros.dataInicial) && (filtros.dataFinal)) {
            setCarregando(true);
            api.get(`/OData/Fatura?$filter=Emissao ge ${filtros.dataInicial} and Emissao le ${filtros.dataFinal}`)
                .then(response => {
                    let linhas: any[] = [];

                    for (let i = 0; i < response.data.value.length; i++) {
                        linhas.push({
                            id: response.data.value[i].Id,
                            codigo: response.data.value[i].Codigo,
                            emissao: response.data.value[i].Emissao,
                            vencimento: response.data.value[i].Vencimento,
                            pagamento: response.data.value[i].Pagamento,
                            valor: response.data.value[i].Valor,
                            desconto: response.data.value[i].Desconto,
                            acrescimo: response.data.value[i].Acrescimo,
                            valorrecebido: response.data.value[i].ValorRecebido,
                            pdf: response.data.value[i].Pdf,
                        });
                    }

                    setDados(linhas);
                    setCarregando(false);
                }).catch((error: AxiosError<ApiError>) => {
                    setCarregando(false);
                    setMessageWarning(tratarErroApi(error, "Não foi possível realizar a consulta: "));
                    setShowWarning(true);
                });
        }
    }, [filtros]);

    const onSubmit: SubmitHandler<Filtros> = data => {
        const dataInicial = new Date(data.dataInicial);
        const dataFinal = new Date(data.dataFinal);

        if (dataInicial.getTime() > dataFinal.getTime()) {
            setMessageWarning(["Data inicial maior que a data final."]);
            setShowWarning(true);
        } else {
            setFiltros(data);
        }
    }

    const handleDownloadFatura = useEventCallback(
        (id: GridRowId) => () => {
            const item: any = dados.filter(item => item.id === id);
            handleDownload(item[0].pdf);
        },
    );

    function handleDownload(key: string) {
        if (key.trim() === "") {
            setMessageWarning(["Não foi localizado arquivo para realizar o download"]);
            setShowWarning(true);
        } else {
            let data = {
                key: key,
            }
            api.post(`/AwsS3/ObterURLTemporariaArquivo/`, data)
                .then(response => {
                    window.open(response.data, "_blank");
                }).catch((error: AxiosError<ApiError>) => {
                    setCarregando(false);
                    setMessageWarning(tratarErroApi(error, "Não foi possível realizar o download: "));
                    setShowWarning(true);
                });
        }
    }

    return (
        <RxlibLayout>
            <Breadcrumb
                itens={breadcrumbs} />
            <form onSubmit={handleSubmit(onSubmit)} className="mb-2">
                <div className="container-fluid">
                    <div className="row px-1">
                        <div className="col-2">
                            <InputLabel
                                type="date"
                                maxLength={10}
                                readOnly={false}
                                autoFocus={true}
                                name="dataInicial"
                                id="inputDataInicial"
                                label="Data inicial:"
                                placeholder="  /  /    "
                                defaultValue={dataInicialString}
                                className="rxlib-input-label-coluna"
                                referencia={register({ required: true })} />
                        </div>
                        <div className="col-2">
                            <InputLabel
                                type="date"
                                maxLength={10}
                                readOnly={false}
                                name="dataFinal"
                                autoFocus={false}
                                id="inputDataFinal"
                                label="Data final:"
                                placeholder="  /  /    "
                                defaultValue={dataFinalString}
                                className="rxlib-input-label-coluna"
                                referencia={register({ required: true })} />
                        </div>
                        <div className="col-2 rxlib-celula-botao">
                            <Button
                                type="submit"
                                texto="Consultar"
                                classStyle="btn-rxlib" />
                        </div>
                        <div className="col-6"></div>
                    </div>
                </div>
            </form>
            <div className="mb-5 container-fluid cargas-sac-grid">
                <Grid
                    linhas={dados}
                    colunas={colunas}
                    carregando={carregando} />
            </div>
            <ModalWarning
                show={showWarning}
                onHide={handleHide}
                message={messageWarning} />
        </RxlibLayout>
    );
}

export default FaturaVencimento;
import { AxiosError } from "axios";
import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import Button from "../../rxlib/components/buttons/button";
import { GridColDef, GridRowsProp } from "@mui/x-data-grid/models";

import api from "../../services/api";
import Grid from "../../components/grid";
import { ApiError } from "../../services/tipos";
import { ModalWarning } from "../../rxlib/components/modal/modal-warning";

import {
    tratarErroApi,
    formatarDataHora,
} from "../../rxlib/services/utilitarios";

interface ModalOcorrenciaProps {
    show: boolean;
    idConhecimento: string;
    onHide: () => void;
}

export function ModalOcorrencia(props: ModalOcorrenciaProps) {
    const [dados, setDados] = useState<GridRowsProp>([]);
    const [carregando, setCarregando] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [messageWarning, setMessageWarning] = useState<string[]>([]);

    const colunas: GridColDef[] = [
        { field: "codigo", headerName: "Código", width: 100, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell" },
        { field: "data", headerName: "Data", width: 180, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell" },
        { field: "descricao", headerName: "Descrição", width: 480, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell" },
    ];

    useEffect(() => {
        if (props.idConhecimento !== "") {
            setCarregando(true);
            api.get(`/Ocorrencia/Conhecimento/${props.idConhecimento}`)
                .then(response => {
                    let linhas: any[] = [];
                    for (let i = 0; i < response.data.length; i++) {
                        linhas.push({
                            id: response.data[i].id,
                            data: formatarDataHora(response.data[i].data),
                            codigo: response.data[i].codigo,
                            descricao: response.data[i].descricao
                        });
                    }

                    setDados(linhas);
                    setCarregando(false);
                }).catch((error: AxiosError<ApiError>) => {
                    setCarregando(false);
                    setMessageWarning(tratarErroApi(error, "Não foi possível realizar a consulta: "));
                    setShowWarning(true);
                });
        }
    }, [props.idConhecimento, props.show]);

    return (
        <Modal enforceFocus={false} show={props.show} onHide={props.onHide} size="lg" centered>
            <Modal.Header>
                <Modal.Title>
                    Ocorrências
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ height: 350 }}>
                    <Grid
                        linhas={dados}
                        colunas={colunas}
                        carregando={carregando} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    type="button"
                    texto="Fecha"
                    classStyle="btn-rxlib"
                    onClick={props.onHide} />
            </Modal.Footer>
            <ModalWarning
                show={showWarning}
                message={messageWarning}
                onHide={() => setShowWarning(false)} />
        </Modal>
    );
}
import { AxiosError } from "axios";
import { useState, useEffect } from "react";
import { useEventCallback } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { GridActionsCellItem, GridRowId } from "@mui/x-data-grid";
import { GridColDef, GridRowsProp } from "@mui/x-data-grid/models";

import api from "../../services/api";
import Grid from "../../components/grid";
import { ApiError } from "../../services/tipos";
import { ModalOcorrencia } from "./ocorrencias";
import Button from "../../rxlib/components/buttons/button";
import InputLabel from "../../rxlib/components/input-label";
import { RxlibLayout } from "../../rxlib/components/layout/rxlib-Layout";
import { ModalWarning } from "../../rxlib/components/modal/modal-warning";
import Breadcrumb, { BreadcrumbItem } from "../../rxlib/components/breadcrumb";

import {
    tratarErroApi,
    obterDataAtual,
    formatarPorTipo,
    obterDataAtualMenosDias,
} from "../../rxlib/services/utilitarios";

interface Filtros {
    dataInicial: string;
    dataFinal: string;
}

function InformacaoCarga() {
    const [dados, setDados] = useState<GridRowsProp>([]);
    const [carregando, setCarregando] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [idConhecimento, setIdConhecimento] = useState<string>("");
    const [messageWarning, setMessageWarning] = useState<string[]>([]);
    const [showOcorrencia, setShowOcorrecina] = useState<boolean>(false);

    const diasPeriodoInicialFiltro = 7;
    const dataFinalString = obterDataAtual();
    const dataInicialString = obterDataAtualMenosDias(diasPeriodoInicialFiltro);

    const [filtros, setFiltros] = useState<Filtros>({
        dataInicial: dataInicialString,
        dataFinal: dataFinalString,
    });

    const { register, handleSubmit } = useForm<Filtros>();

    const handleHide = () => setShowWarning(false);

    const breadcrumbs: BreadcrumbItem[] = [
        { texto: "Home", link: "/home" },
        { texto: "Informações das cargas", link: "" },
    ];

    const colunas: GridColDef[] = [
        {
            width: 30,
            minWidth: 30,
            type: "actions",
            field: "actions",
            headerName: "Opções",
            cellClassName: "cargas-sac-grid-cell",
            headerClassName: "cargas-sac-grid-header cargas-sac-grid-header-icon",
            getActions: (params) => [
                <GridActionsCellItem
                    showInMenu={true}
                    label="Download XML"
                    onClick={handleDownloadXML(params.id)}
                    icon={<i className="fas fa-file-export" />} />,
                <GridActionsCellItem
                    showInMenu={true}
                    label="Download DACTE"
                    onClick={handleDownloadDACTE(params.id)}
                    icon={<i className="fas fa-file-pdf" />} />,
                <GridActionsCellItem
                    showInMenu={true}
                    label="Download comprovante de entrega"
                    icon={<i className="fas fa-file-image" />}
                    onClick={handleDownloadComprovanteEntrega(params.id)} />,
                <GridActionsCellItem
                    showInMenu={true}
                    label="Exibir detalhes"
                    onClick={handleConsultaPublica(params.id)}
                    icon={<i className="fas fa-sheet-plastic" />} />,
                <GridActionsCellItem
                    showInMenu={true}
                    label="Exibir ocorrências"
                    onClick={handleShowModalOcorrencia(params.id)}
                    icon={<i className="fas fa-table-list" />} />,
            ],
            renderHeader: () => (
                <i className="fa-solid fa-gear" />
            ),
        },
        { field: "numero", headerName: "Número da NFe", width: 140, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell", type: "number", headerAlign: "left", align: "left" },
        { field: "data", headerName: "Data da NFe", width: 120, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell", type: "date", valueFormatter: ({ value }) => value && formatarPorTipo("date", value) },
        { field: "empresa", headerName: "Empresa", width: 90, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell" },
        { field: "serie", headerName: "Serie", width: 60, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell" },
        { field: "ctrc", headerName: "CTRC", width: 120, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell", type: "number", headerAlign: "left", align: "left" },
        { field: "dataconhecimento", headerName: "Data conhecimento", width: 170, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell", type: "date", valueFormatter: ({ value }) => value && formatarPorTipo("date", value) },
        { field: "dataembarque", headerName: "Data embarque", width: 140, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell", type: "date", valueFormatter: ({ value }) => value && formatarPorTipo("date", value) },
        { field: "previsaoentrega", headerName: "Previsão entrega", width: 150, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell", type: "date", valueFormatter: ({ value }) => value && formatarPorTipo("date", value) },
        { field: "dataentrega", headerName: "Data entrega", width: 120, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell", type: "date", valueFormatter: ({ value }) => value && formatarPorTipo("date", value) },
        { field: "peso", headerName: "Peso da NFe", width: 110, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell", type: "number", valueFormatter: ({ value }) => formatarPorTipo("currency", value) },
        { field: "volume", headerName: "Volume NFe", width: 110, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell", type: "number", valueFormatter: ({ value }) => formatarPorTipo("currency", value) },
        { field: "valor", headerName: "Valor da NFe", width: 110, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell", type: "number", valueFormatter: ({ value }) => formatarPorTipo("currency", value) },
        { field: "origem", headerName: "Origem", width: 200, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell" },
        { field: "destino", headerName: "Destino", width: 200, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell" },
        { field: "remetente", headerName: "Remetente", width: 400, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell" },
        { field: "destinatario", headerName: "Destinatário", width: 400, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell" },
        { field: "totalfrete", headerName: "Total do frete", width: 150, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell", type: "number", valueFormatter: ({ value }) => formatarPorTipo("currency", value) },
        { field: "ultimaocorrencia", headerName: "Última ocorrência", width: 600, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell" },
    ];

    useEffect(() => {
        if ((filtros.dataInicial) && (filtros.dataFinal)) {
            setCarregando(true);
            api.get(`/NotaFiscal/${filtros.dataInicial}T00:00:00.000/${filtros.dataFinal}T23:59:59.000`)
                .then(response => {
                    let linhas: any[] = [];

                    for (let i = 0; i < response.data.length; i++) {
                        linhas.push({
                            id: response.data[i].id,
                            numero: response.data[i].numero,
                            idConhecimento: response.data[i].idConhecimento,
                            data: response.data[i].data,
                            empresa: response.data[i].conhecimento.empresa.codigo,
                            serie: response.data[i].conhecimento.serie,
                            ctrc: response.data[i].conhecimento.ctrc,
                            dataconhecimento: response.data[i].conhecimento.data,
                            dataembarque: response.data[i].conhecimento.dataEmbarque,
                            previsaoentrega: response.data[i].conhecimento.dataPrevisaoEntrega,
                            dataentrega: response.data[i].conhecimento.dataEntrega,
                            peso: response.data[i].peso,
                            volume: response.data[i].volume,
                            valor: response.data[i].valor,
                            origem: response.data[i].conhecimento.cidadeOrigem.nome,
                            destino: response.data[i].conhecimento.cidadeDestino.nome,
                            remetente: response.data[i].conhecimento.remetente.nome,
                            destinatario: response.data[i].conhecimento.destinatario.nome,
                            totalfrete: response.data[i].conhecimento.totalFrete,
                            ultimaocorrencia: response.data[i].conhecimento.descricaoUltimaOcorrencia,
                            xml: response.data[i].conhecimento.xml,
                            dacte: response.data[i].conhecimento.dacte,
                            comprovanteentrega: response.data[i].conhecimento.comprovanteEntrega,
                        });
                    }

                    setDados(linhas);
                    setCarregando(false);
                }).catch((error: AxiosError<ApiError>) => {
                    setCarregando(false);
                    setMessageWarning(tratarErroApi(error, "Não foi possível realizar a consulta: "));
                    setShowWarning(true);
                });
        }
    }, [filtros]);

    const onSubmit: SubmitHandler<Filtros> = data => {
        const dataFinal = new Date(data.dataFinal);
        const dataInicial = new Date(data.dataInicial);

        if (dataInicial.getTime() > dataFinal.getTime()) {
            setMessageWarning(["Data inicial maior que a data final."]);
            setShowWarning(true);
        } else {
            setFiltros(data);
        }
    };

    const handleDownloadXML = useEventCallback(
        (id: GridRowId) => () => {
            const item: any = dados.filter(item => item.id === id);
            handleDownload(item[0].xml);
        },
    );

    const handleDownloadDACTE = useEventCallback(
        (id: GridRowId) => () => {
            const item: any = dados.filter(item => item.id === id);
            handleDownload(item[0].dacte);
        },
    );

    const handleDownloadComprovanteEntrega = useEventCallback(
        (id: GridRowId) => () => {
            const item: any = dados.filter(item => item.id === id);
            handleDownload(item[0].comprovanteentrega);
        },
    );

    function handleDownload(key: string) {
        if (key.trim() === "") {
            setMessageWarning(["Não foi localizado arquivo para realizar o download"]);
            setShowWarning(true);
        } else {
            let data = {
                key: key,
            }
            api.post(`/AwsS3/ObterURLTemporariaArquivo/`, data)
                .then(response => {
                    window.open(response.data, "_blank");
                }).catch((error: AxiosError<ApiError>) => {
                    setCarregando(false);
                    setMessageWarning(tratarErroApi(error, "Não foi possível realizar o download: "));
                    setShowWarning(true);
                });
        }
    }

    const handleConsultaPublica = useEventCallback(
        (id: GridRowId) => () => {
            if (id !== "") {
                window.open(`/posicaocarga/${id}`, "_blank");
            }
        },
    );

    const handleShowModalOcorrencia = useEventCallback(
        (id: GridRowId) => () => {
            const item: any = dados.filter(item => item.id === id);
            setIdConhecimento(item[0].idConhecimento);
            setShowOcorrecina(true);
        },
    );

    return (
        <RxlibLayout>
            <Breadcrumb
                itens={breadcrumbs} />
            <form onSubmit={handleSubmit(onSubmit)} className="mb-2">
                <div className="container-fluid">
                    <div className="row px-1">
                        <div className="col-2">
                            <InputLabel
                                type="date"
                                maxLength={10}
                                readOnly={false}
                                autoFocus={true}
                                name="dataInicial"
                                id="inputDataInicial"
                                label="Data inicial:"
                                placeholder="  /  /    "
                                defaultValue={dataInicialString}
                                className="rxlib-input-label-coluna"
                                referencia={register({ required: true })} />
                        </div>
                        <div className="col-2">
                            <InputLabel
                                type="date"
                                maxLength={10}
                                readOnly={false}
                                name="dataFinal"
                                autoFocus={false}
                                label="Data final:"
                                id="inputDataFinal"
                                placeholder="  /  /    "
                                defaultValue={dataFinalString}
                                className="rxlib-input-label-coluna"
                                referencia={register({ required: true })} />
                        </div>
                        <div className="col-2 rxlib-celula-botao">
                            <Button
                                type="submit"
                                texto="Consultar"
                                classStyle="btn-rxlib" />
                        </div>
                        <div className="col-6"></div>
                    </div>
                </div>
            </form>
            <div className="mb-5 container-fluid cargas-sac-grid">
                <Grid
                    linhas={dados}
                    colunas={colunas}
                    carregando={carregando} />
            </div>
            <ModalWarning
                show={showWarning}
                onHide={handleHide}
                message={messageWarning} />
            <ModalOcorrencia
                show={showOcorrencia}
                idConhecimento={idConhecimento}
                onHide={() => setShowOcorrecina(false)} />
        </RxlibLayout>
    );
}

export default InformacaoCarga;
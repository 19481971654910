import "./posicao-carga.css";
import { AxiosError } from "axios";
import api from "../../services/api";
import { Redirect } from "react-router";
import logo from "../../images/icone.png";
import { useEffect, useState } from "react";
import Spinner from "../../rxlib/components/spinner";

import {
    Cliente,
    ApiError,
    Ocorrencia,
    NotaFiscal,
    PosicaoCargaProps,
} from "../../services/tipos";

import {
    formatarCep,
    formatarData,
    formatarCpfCnpj,
    formatarDataHora,
    obterDataHoraAtualFormatada,
} from "../../rxlib/services/utilitarios";

function PosicaoCarga(props: PosicaoCargaProps) {
    const [carregando, setCarregando] = useState<boolean>(false);
    const [ocorrencias, setOcorrencias] = useState<Ocorrencia[]>([]);
    const [naoRealizado, setNaoRealizado] = useState<boolean>(false);
    const [naoLocalizado, setNaoLocalizado] = useState<boolean>(false);

    const [notaFiscal, setNotaFiscal] = useState<NotaFiscal>({
        id: "",
        peso: "",
        chave: "",
        valor: "",
        numero: "",
        data: "",
        volume: "",
        idConhecimento: "",
        conhecimento: {
            id: "",
            idLicenciada: "",
            idEmpresa: "",
            empresa: {
                id: "",
                idLicenciada: "",
                codigo: "",
                nome: "",
                emiteCte: false,
            },
            serie: "",
            ctrc: "",
            tipo: "",
            status: "",
            data: "",
            dataEmbarque: "",
            dataPrevisaoEntrega: "",
            dataEntrega: "",
            dataChegada: "",
            dataColeta: "",
            idCidadeOrigem: "",
            cidadeOrigem: {
                id: "",
                idLicenciada: "",
                nome: "",
                uf: "",
            },
            idCidadeDestino: "",
            cidadeDestino: {
                id: "",
                idLicenciada: "",
                nome: "",
                uf: "",
            },
            idRemetente: "",
            remetente: {
                id: "",
                idLicenciada: "",
                cnpjCpf: "",
                nome: "",
                endereco: "",
                numero: "",
                complemento: "",
                bairro: "",
                idCidade: "",
                cidade: {
                    id: "",
                    idLicenciada: "",
                    nome: "",
                    uf: "",
                },
                cep: "",
                idEmpresa: "",
                empresa: {
                    id: "",
                    idLicenciada: "",
                    codigo: "",
                    nome: "",
                    emiteCte: false,
                },
                tabelaPreferencial: "",
            },
            idDestinatario: "",
            destinatario: {
                id: "",
                idLicenciada: "",
                cnpjCpf: "",
                nome: "",
                endereco: "",
                numero: "",
                complemento: "",
                bairro: "",
                idCidade: "",
                cidade: {
                    id: "",
                    idLicenciada: "",
                    nome: "",
                    uf: "",
                },
                cep: "",
                idEmpresa: "",
                empresa: {
                    id: "",
                    idLicenciada: "",
                    codigo: "",
                    nome: "",
                    emiteCte: false,
                },
                tabelaPreferencial: "",
            },
            idPagador: "",
            pagador: {
                id: "",
                idLicenciada: "",
                cnpjCpf: "",
                nome: "",
                endereco: "",
                numero: "",
                complemento: "",
                bairro: "",
                idCidade: "",
                cidade: {
                    id: "",
                    idLicenciada: "",
                    nome: "",
                    uf: "",
                },
                cep: "",
                idEmpresa: "",
                empresa: {
                    id: "",
                    idLicenciada: "",
                    codigo: "",
                    nome: "",
                    emiteCte: false,
                },
                tabelaPreferencial: "",
            },
            recebedor: "",
            descricaoUltimaOcorrencia: "",
            volume: "",
            peso: "",
            valor: "",
            aliqIcms: "",
            icms: "",
            totalFrete: "",
            idManifesto: "",
            manifesto: {
                id: "",
                idLicenciada: "",
                idEmpresa: "",
                empresa: {
                    id: "",
                    idLicenciada: "",
                    codigo: "",
                    nome: "",
                    emiteCte: false,
                },
                codigo: 0,
            },
            idMapaEntrega: "",
            mapaEntrega: {
                id: "",
                idLicenciada: "",
                idEmpresa: "",
                empresa: {
                    id: "",
                    idLicenciada: "",
                    codigo: "",
                    nome: "",
                    emiteCte: false,
                },
                codigo: 0,
            },
            situacaoAtual: "",
            xml: "",
            dacte: "",
            comprovanteEntrega: "",
        },
        ocorrencias: [
            {
                id: "",
                data: "",
                codigo: "",
                descricao: "",
            }
        ]
    });

    useEffect(() => {
        setCarregando(true);
        if (props.match.params.idNota !== undefined) {
            api.get(`/NotaFiscal/${props.match.params.idNota}`)
                .then(response => {
                    setNotaFiscal(response.data);
                    setCarregando(false);
                }).catch((error: AxiosError<ApiError>) => {
                    tratarErro(error);
                });
        } else {
            api.get(`/NotaFiscal/ConsultaPublica/${props.match.params.documento}/${props.match.params.numeroNota}`)
                .then(response => {
                    setNotaFiscal(response.data);
                    setListaOcorrencias(response.data.ocorrencias);
                    setCarregando(false);
                }).catch((error: AxiosError<ApiError>) => {
                    tratarErro(error);
                });
        }
    }, [props.match.params.idNota, props.match.params.documento, props.match.params.numeroNota]);

    useEffect(() => {
        if ((props.match.params.idNota !== undefined) && (notaFiscal.idConhecimento !== "")) {
            setCarregando(true);
            api.get(`/Ocorrencia/Conhecimento/${notaFiscal.idConhecimento}`)
                .then(response => {
                    setListaOcorrencias(response.data);
                    setCarregando(false);
                }).catch((error: AxiosError<ApiError>) => {
                    tratarErro(error);
                });
        }
    }, [props.match.params.idNota, notaFiscal.idConhecimento]);

    function setListaOcorrencias(data: any[]) {
        let linhas: Ocorrencia[] = [];
        for (let i = 0; i < data.length; i++) {
            linhas.push({
                id: data[i].id,
                codigo: data[i].codigo,
                descricao: data[i].descricao,
                data: formatarDataHora(data[i].data),
            });
        }
        setOcorrencias(linhas);
    }

    function tratarErro(error: AxiosError<ApiError>) {
        if (error.response) {
            if (error.response.status === 404) {
                setNaoLocalizado(true);
            } else {
                setNaoRealizado(true);
            }
        } else {
            setNaoRealizado(true);
        }
    }

    function getProgress(): string {
        let progress = "progress-bar";

        if (formatarData(notaFiscal.data) !== "") {
            progress += " progresso-12";
        }

        if ((notaFiscal.conhecimento.manifesto) && (notaFiscal.conhecimento.manifesto.codigo > 0)) {
            progress += " progresso-33";
        }

        if ((notaFiscal.conhecimento.dataChegada) && (formatarData(notaFiscal.conhecimento.dataChegada) !== "")) {
            progress += " progresso-55";
        }

        if ((notaFiscal.conhecimento.mapaEntrega) && (notaFiscal.conhecimento.mapaEntrega.codigo > 0)) {
            progress += " progresso-76";
        }

        if ((notaFiscal.conhecimento.dataEntrega) && (formatarData(notaFiscal.conhecimento.dataEntrega) !== "")) {
            progress += " progresso-100";
        }

        return progress;
    }

    function getLogradouro(cliente: Cliente): string {
        let logradouro = cliente.endereco;

        if (cliente.numero !== "") {
            logradouro += ", " + cliente.numero;
        }

        if (cliente.complemento !== "") {
            logradouro += ", " + cliente.complemento;
        }

        return logradouro;
    }

    return (
        <>
            {
                naoLocalizado
                    ? <Redirect to="/consultapublica/naolocalizado" />
                    : ""
            }
            {
                naoRealizado
                    ? <Redirect to="/consultapublica/naorealizado" />
                    : ""
            }
            <div className="posicao-carga-content">
                {
                    carregando
                        ? <div className="container-fluid posicao-carga-spinner">
                            <Spinner
                                classStyle="rxlib-spinner" />
                        </div>
                        : <div className="container-fluid posicao-carga">
                            <div className="row linha-titulo titulo">
                                <div className="col-4">
                                    <a href="/consultapublica" id="logo">
                                        <img src={logo} alt="Logo" className="logo" />
                                    </a>
                                </div>
                                <div className="col-4">
                                    Consulta Pública
                                </div>
                                <div className="col-4">
                                    Conhecimento:
                                    <span className="valor"> {notaFiscal.conhecimento.empresa.codigo};{notaFiscal.conhecimento.serie};{notaFiscal.conhecimento.ctrc}</span>
                                </div>
                            </div>
                            <hr />
                            <div className="row linha-andamento">
                                <div className="col-3">
                                    Aguardando Embarque
                                </div>
                                <div className="col-2">
                                    Em Trânsito
                                </div>
                                <div className="col-3">
                                    Aguardando Entrega
                                </div>
                                <div className="col-2">
                                    Em Entrega
                                </div>
                                <div className="col-2">
                                    Entregue
                                </div>
                            </div>
                            <div className="row linha-andamento">
                                <div className="col-3">
                                    |
                                </div>
                                <div className="col-2">
                                    |
                                </div>
                                <div className="col-3">
                                    |
                                </div>
                                <div className="col-2">
                                    |
                                </div>
                                <div className="col-2">
                                    |
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="progress">
                                        <div className={getProgress()} role="progressbar" aria-valuemin={0} aria-valuemax={100}></div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row informacao">
                                <div className="col-12 campo">
                                    Situação Atual:
                                    <span className="valor"> {notaFiscal.conhecimento.situacaoAtual}</span>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-6">
                                    <div className="titulo-cliente">
                                        Remetente:
                                    </div>
                                    <span>{notaFiscal.conhecimento.remetente.nome}</span>
                                    <br />
                                    <span>{formatarCpfCnpj(notaFiscal.conhecimento.remetente.cnpjCpf)}</span>
                                    <br />
                                    <span>{getLogradouro(notaFiscal.conhecimento.remetente)}</span>
                                    <br />
                                    <span>{notaFiscal.conhecimento.remetente.bairro}</span>
                                    <br />
                                    <span>{notaFiscal.conhecimento.remetente.cidade.nome} - {notaFiscal.conhecimento.remetente.cidade.uf}</span>
                                    <br />
                                    <span>{formatarCep(notaFiscal.conhecimento.remetente.cep)}</span>
                                </div>
                                <div className="col-6">
                                    <div className="titulo-cliente">
                                        Destinatário:
                                    </div>
                                    <span>{notaFiscal.conhecimento.destinatario.nome}</span>
                                    <br />
                                    <span>{formatarCpfCnpj(notaFiscal.conhecimento.destinatario.cnpjCpf)}</span>
                                    <br />
                                    <span>{getLogradouro(notaFiscal.conhecimento.destinatario)}</span>
                                    <br />
                                    <span>{notaFiscal.conhecimento.destinatario.bairro}</span>
                                    <br />
                                    <span>{notaFiscal.conhecimento.destinatario.cidade.nome} - {notaFiscal.conhecimento.destinatario.cidade.uf}</span>
                                    <br />
                                    <span>{formatarCep(notaFiscal.conhecimento.destinatario.cep)}</span>
                                </div>
                            </div>
                            <hr />
                            <div className="row informacao">
                                <div className="col-12 campo">
                                    Previsão de entrega ao destinatário:
                                    <span className="previsao"> {
                                        notaFiscal.conhecimento.dataPrevisaoEntrega
                                            ? formatarDataHora(notaFiscal.conhecimento.dataPrevisaoEntrega)
                                            : ""
                                    }</span>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-12 informacao-rastreamento">
                                    Informações Rastreamento
                                </div>
                            </div>
                            <hr />
                            <table className="table informacao-rastreamento-table">
                                <tbody>
                                    <tr>
                                        <th scope="col">Status</th>
                                        <th scope="col">Data</th>
                                    </tr>
                                    <tr>
                                        <th scope="row">Emissão</th>
                                        <td>
                                            {formatarDataHora(notaFiscal.conhecimento.data)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Coleta</th>
                                        <td>
                                            {
                                                notaFiscal.conhecimento.dataColeta
                                                    ? formatarDataHora(notaFiscal.conhecimento.dataColeta)
                                                    : ""
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Embarque</th>
                                        <td>
                                            {
                                                notaFiscal.conhecimento.dataEmbarque
                                                    ? formatarDataHora(notaFiscal.conhecimento.dataEmbarque)
                                                    : ""
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Chegada</th>
                                        <td>
                                            {
                                                notaFiscal.conhecimento.dataChegada
                                                    ? formatarDataHora(notaFiscal.conhecimento.dataChegada)
                                                    : ""
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Entrega
                                            <div className="recebido-por">
                                                Recebido por:
                                                <span className="recebedor"> {notaFiscal.conhecimento.recebedor}</span>
                                            </div>
                                        </th>
                                        <td>
                                            {
                                                notaFiscal.conhecimento.dataEntrega
                                                    ? formatarDataHora(notaFiscal.conhecimento.dataEntrega)
                                                    : ""
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="row">
                                <div className="col-12 informacao-rastreamento">
                                    Ocorrências
                                </div>
                            </div>
                            <hr />
                            <table className="table informacao-rastreamento-table">
                                <tbody>
                                    <tr>
                                        <th>Código</th>
                                        <th>Data</th>
                                        <th>Descrição</th>
                                    </tr>
                                    {
                                        ocorrencias.map((ocorrencia, index) =>
                                            <tr key={index}>
                                                <td>{ocorrencia.codigo}</td>
                                                <td>{ocorrencia.data}</td>
                                                <td>{ocorrencia.descricao}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                            <div className="row">
                                <div className="col-12 gerado-em">
                                    Gerado em: {obterDataHoraAtualFormatada()}
                                </div>
                            </div>
                        </div>
                }
            </div>
        </>
    );
}

export default PosicaoCarga;
import { AxiosError } from "axios";
import { useState, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { GridColDef, GridRowsProp } from "@mui/x-data-grid/models";

import api from "../../../services/api";
import Grid from "../../../components/grid";
import { ApiError } from "../../../services/tipos";
import Button from "../../../rxlib/components/buttons/button";
import InputLabel from "../../../rxlib/components/input-label";
import ButtonLink from "../../../rxlib/components/buttons/button-link";
import { RxlibLayout } from "../../../rxlib/components/layout/rxlib-Layout";
import { SelectLabel } from "../../../rxlib/components/select/select-label";
import { ModalWarning } from "../../../rxlib/components/modal/modal-warning";
import Breadcrumb, { BreadcrumbItem } from "../../../rxlib/components/breadcrumb";

import {
    tratarErroApi,
    obterDataAtual,
    formatarPorTipo,
    obterDataAtualMenosDias,
} from "../../../rxlib/services/utilitarios";

interface Filtros {
    dataInicial: string;
    dataFinal: string;
    status: string;
}

function CotacaoPrecoListagem() {
    const [dados, setDados] = useState<GridRowsProp>([]);
    const [carregando, setCarregando] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [messageWarning, setMessageWarning] = useState<string[]>([]);

    const diasPeriodoInicialFiltro = 7;
    const dataInicialString = obterDataAtualMenosDias(diasPeriodoInicialFiltro);
    const dataFinalString = obterDataAtual();

    const [filtros, setFiltros] = useState<Filtros>({
        dataInicial: dataInicialString,
        dataFinal: dataFinalString,
        status: "",
    });

    const { register, handleSubmit } = useForm<Filtros>();

    const handleHide = () => setShowWarning(false);

    const breadcrumbs: BreadcrumbItem[] = [
        { texto: "Home", link: "/home" },
        { texto: "Cotação de preço de carga", link: "" },
    ];

    const status: any = [
        { id: "0", nome: "Selecione" },
        { id: "B", nome: "Baixada" },
        { id: "C", nome: "Cancelada" },
        { id: "F", nome: "Confirmada" },
        { id: "I", nome: "A Confirmar" },
        { id: "AS", nome: "Aguardando sincronização" },
    ];

    const colunas: GridColDef[] = [
        { field: "empresa", headerName: "Empresa", width: 90, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell" },
        { field: "data", headerName: "Data", width: 110, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell", type: "date", valueFormatter: ({ value }) => value && formatarPorTipo("date", value) },
        { field: "remetente", headerName: "Remetente", width: 400, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell" },
        { field: "origem", headerName: "Origem", width: 200, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell" },
        { field: "destino", headerName: "Destino", width: 200, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell" },
        { field: "contato", headerName: "Contato", width: 400, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell" },
        { field: "status", headerName: "Status", width: 220, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell" },
        { field: "totalfrete", headerName: "Total do Frete", width: 150, headerClassName: "cargas-sac-grid-header", cellClassName: "cargas-sac-grid-cell", type: "number", valueFormatter: ({ value }) => formatarPorTipo("currency", value) },
    ];

    useEffect(() => {
        if ((filtros.dataInicial) && (filtros.dataFinal) && (filtros.status)) {
            setCarregando(true);
            let url = `/OData/CotacaoPreco?$expand=Empresa, Remetente, CidadeOrigem, CidadeDestino&$filter=Data ge ${filtros.dataInicial} and Data le ${filtros.dataFinal}`;

            if ((filtros.status !== "") && (filtros.status !== "0")) {
                url += ` and Status eq '${filtros.status}'`;
            }

            api.get(url)
                .then(response => {
                    let linhas: any[] = [];

                    for (let i = 0; i < response.data.value.length; i++) {
                        linhas.push({
                            id: response.data.value[i].Id,
                            empresa: response.data.value[i].Empresa.Codigo,
                            data: response.data.value[i].Data,
                            remetente: response.data.value[i].Remetente.Nome,
                            origem: response.data.value[i].CidadeOrigem.Nome,
                            destino: response.data.value[i].CidadeDestino.Nome,
                            contato: response.data.value[i].NomeContato,
                            status: formatarPorTipo("statusCotacaoPreco", response.data.value[i].Status),
                            totalfrete: response.data.value[i].TotalFrete,
                        });
                    }

                    setDados(linhas);
                    setCarregando(false);
                }).catch((error: AxiosError<ApiError>) => {
                    setCarregando(false);
                    setMessageWarning(tratarErroApi(error, "Não foi possível realizar a consulta: "));
                    setShowWarning(true);
                });
        }
    }, [filtros]);

    const onSubmit: SubmitHandler<Filtros> = data => {
        const dataInicial = new Date(data.dataInicial);
        const dataFinal = new Date(data.dataFinal);

        if (dataInicial.getTime() > dataFinal.getTime()) {
            setMessageWarning(["Data inicial maior que a data final."]);
            setShowWarning(true);
        } else {
            setFiltros(data);
        }
    }

    return (
        <RxlibLayout>
            <Breadcrumb
                itens={breadcrumbs} />
            <form onSubmit={handleSubmit(onSubmit)} className="mb-2">
                <div className="container-fluid">
                    <div className="row px-1">
                        <div className="col-2">
                            <InputLabel
                                type="date"
                                maxLength={10}
                                readOnly={false}
                                autoFocus={true}
                                name="dataInicial"
                                id="inputDataInicial"
                                label="Data inicial:"
                                placeholder="  /  /    "
                                defaultValue={dataInicialString}
                                className="rxlib-input-label-coluna"
                                referencia={register({ required: true })} />
                        </div>
                        <div className="col-2">
                            <InputLabel
                                type="date"
                                maxLength={10}
                                readOnly={false}
                                name="dataFinal"
                                autoFocus={false}
                                label="Data final:"
                                id="inputDataFinal"
                                placeholder="  /  /    "
                                defaultValue={dataFinalString}
                                className="rxlib-input-label-coluna"
                                referencia={register({ required: true })} />
                        </div>
                        <div className="col-2">
                            <SelectLabel
                                action=""
                                foco="nao"
                                name="status"
                                itens={status}
                                required="nao"
                                label="Status:"
                                id="inputStatus"
                                ariaLabel="status"
                                valorSelecionado=""
                                campoExibicaoRegistro="nome"
                                campoIdentificadorRegistro="id"
                                className="rxlib-select-label-coluna"
                                referencia={register({ required: false })} />
                        </div>
                        <div className="col-2 rxlib-celula-botao">
                            <Button
                                type="submit"
                                texto="Consultar"
                                classStyle="btn-rxlib" />
                        </div>
                        <div className="col-2"></div>
                        <div className="col-2 rxlib-celula-botao">
                            <ButtonLink
                                texto="Nova Cotação"
                                classStyle="btn-rxlib"
                                link="/cotacaopreco/novo" />
                        </div>
                    </div>
                </div>
            </form>
            <div className="mb-5 container-fluid cargas-sac-grid">
                <Grid
                    linhas={dados}
                    colunas={colunas}
                    carregando={carregando} />
            </div>
            <ModalWarning
                show={showWarning}
                onHide={handleHide}
                message={messageWarning} />
        </RxlibLayout>
    );
}

export default CotacaoPrecoListagem;
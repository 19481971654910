/* RXLib Config */

import { Menu } from "../rxlib/components/layout/rxlib-Layout";
import { TipoColunaTabelaBase } from "../rxlib/components/table";
import { RequestType } from "../rxlib/components/select/select-label-async";

/* SelectLabelAsync */

export const SelectLabelAsyncPrimaryColor = "#01B4B8";
export const SelectLabelAsyncDisabledColor = "#CCCCCC";
export const SelectLabelAsyncSecondaryColor = "#FFFFFF";

export type DataType = "DadosFixos" | "Cidade" | "Servico" | "Empresa" | "Cliente";

export const RequestTypeArray: RequestType[] = [
    {
        useOdata: true,
        type: "Cidade",
        fieldValue: "Id",
        fieldLabel: "Nome",
        fieldValueLowerCase: "id",
        fieldLabelLowerCase: "nome",
        url: "/OData/Cidade?$filter=contains(toupper(nome),toupper('selectedValue'))",
    },
    {
        useOdata: true,
        type: "Servico",
        fieldValue: "Id",
        fieldLabel: "Nome",
        fieldValueLowerCase: "id",
        fieldLabelLowerCase: "nome",
        url: "/OData/Servico?$filter=contains(toupper(nome),toupper('selectedValue'))",
    },
    {
        useOdata: false,
        type: "Empresa",
        fieldValue: "id",
        fieldLabel: "nome",
        fieldValueLowerCase: "id",
        fieldLabelLowerCase: "nome",
        url: "/Empresa/PodemEmitirDocumentosClientesVinculadosUsuarioPorNome/selectedValue",
    },
    {
        useOdata: false,
        type: "Cliente",
        fieldValue: "id",
        fieldLabel: "nome",
        fieldValueLowerCase: "id",
        fieldLabelLowerCase: "nome",
        url: "/Cliente/VinculadosUsuarioPorNome/selectedValue",
    },
];

/* RxlibLayout */

export const NomeEmpresa = "CargasSac";
export const UsarSidebarLayout = false;
export const SecretKey = "f366b841282d4d17";
export const UsarSidebarBodyGradient = false;
export const ExibirNomeEmpresaNavbarLayout = true;

export function montarMenus(token: string, administrador: boolean): Menu {
    let menus: Menu = {
        items: [],
    };

    return menus;
}

/* Table  */

export type TipoColunaTabela = TipoColunaTabelaBase | "statusCotacaoPreco";
import { useState } from "react";
import { AxiosError } from "axios";
import api from "../../services/caronte";
import { Redirect } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { obterVersao } from "../../services/utilitarios";
import Button from "../../rxlib/components/buttons/button";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { ModalWarning } from "../../rxlib/components/modal/modal-warning";
import { obterLicenciada, tokenExpirado } from "../../rxlib/services/seguranca";
import { AcessoProps, ApiError, FormLogin, LoginProduto } from "../../services/tipos";
import { criptografar, obterAmbiente, tratarErroApi } from "../../rxlib/services/utilitarios";

function Login(props: AcessoProps) {
    const [acao, setAcao] = useState(props.match.params.acao);
    const [carregando, setCarregando] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [messageWarning, setMessageWarning] = useState<string[]>([]);
    const [token, setToken] = useState<string>(useAppSelector(state => state.token));
    const [logado, setLogado] = useState<boolean>(useAppSelector(state => state.logado));
    const [expirado, setExpirado] = useState<boolean>(useAppSelector(state => state.expirado));

    const { register, handleSubmit } = useForm<FormLogin>();

    const dispatch = useAppDispatch();

    const handleHide = () => setShowWarning(false);

    const onSubmit: SubmitHandler<FormLogin> = data => {
        setCarregando(true);

        let login: LoginProduto = {
            email: data.email,
            senha: criptografar(data.senha),
            idProduto: "c4b1b457-5be9-4081-b2f8-06e85aa1bdd0",
        }

        api.post("/Login/Authenticate", login)
            .then(response => {
                setTimeout(() => {
                    dispatch({
                        type: "LOG_IN",
                        token: response.data.token,
                        tenantId: obterLicenciada(response.data.token),
                    });
                    setToken(response.data.token);
                    setLogado(true);
                }, 250);
            }).catch((error: AxiosError<ApiError>) => {
                tratarErro(error);
            });
    }

    function tratarErro(error: AxiosError<ApiError>) {
        setCarregando(false);
        setMessageWarning(tratarErroApi(error, "Não foi possível realizar o login: "));
        setShowWarning(true);
    }

    function showSecaoExpirada() {
        setMessageWarning(["A seção do usuário expirou.", "Realize o login novamente."]);
        setShowWarning(true);
        setExpirado(false);
    }

    function showAcessoBloqueado() {
        setMessageWarning(["O acesso foi bloqueado pois o usuário não esta logado."]);
        setShowWarning(true);
        setAcao("");
    }

    return (
        <>
            {
                !tokenExpirado(token)
                    ? logado
                        ? <Redirect to="/home" />
                        : ""
                    : ""
            }
            {
                expirado
                    ? showSecaoExpirada()
                    : ""
            }
            {
                acao === "acessobloqueado"
                    ? showAcessoBloqueado()
                    : ""
            }
            <div className="login-content d-flex align-items-center">
                <form className="form-signin mx-auto rxlib-login" onSubmit={handleSubmit(onSubmit)}>
                    <div className="text-center mb-3">
                        <label className="rxlib-nome">CargasSac</label>
                    </div>
                    <div className="form-label-group">
                        <input ref={register({ required: true })} name="email" type="email" id="inputEmail" className="form-control" placeholder="Endereço de e-mail" required autoFocus />
                        <label htmlFor="inputEmail">Endereço de e-mail</label>
                    </div>
                    <div className="form-label-group">
                        <input ref={register({ required: true })} name="senha" type="password" id="inputPassword" className="form-control" placeholder="Senha" required />
                        <label htmlFor="inputPassword">Senha</label>
                    </div>
                    <div className="d-grid align-middle">
                        <Button
                            type="submit"
                            texto="Entrar"
                            className="btn-lg"
                            classStyle="btn-rxlib"
                            carregando={carregando}
                            classNameDivSpinner="login-spinner-button" />
                    </div>
                    <p className="mt-3 mb-0 text-muted text-center">
                        © 2024 {obterVersao()}
                        <span className="rxlib-homologacao">
                            {obterAmbiente()}
                        </span>
                    </p>
                </form>
            </div>
            <ModalWarning
                show={showWarning}
                onHide={handleHide}
                message={messageWarning} />
        </>
    );
}

export default Login;
import "./home.css";
import { useState } from "react";
import { AcessoProps } from "../../services/tipos";
import { Card } from "../../rxlib/components/card";
import { RxlibLayout } from "../../rxlib/components/layout/rxlib-Layout";
import { ModalWarning } from "../../rxlib/components/modal/modal-warning";

function Home(props: AcessoProps) {
    const [acao, setAcao] = useState(props.match.params.acao);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [messageWarning, setMessageWarning] = useState<string[]>([]);

    const handleHide = () => setShowWarning(false);

    function showMensagemSemAcesso() {
        setMessageWarning(["Usuário logado não tem permissão para realizar essa ação."]);
        setShowWarning(true);
        setAcao("");
    }

    function showMensagemNaoImplementado() {
        setMessageWarning(["Esta funcionalidade ainda não foi implementada."]);
        setShowWarning(true);
        setAcao("");
    }

    return (
        <>
            {
                acao === "acessobloqueado"
                    ? showMensagemSemAcesso()
                    : ""
            }
            {
                acao === "naoimplementado"
                    ? showMensagemNaoImplementado()
                    : ""
            }
            <RxlibLayout>
                <div className="rxlib-home-menu">
                    <div className="rxlib-home-cards">
                        <div>
                            <a href="/informacaocarga">
                                <Card
                                    subtitulo=""
                                    titulo="Informações das cargas"
                                    classNameIcone="fas fa-info-circle"
                                    texto="Consulta das informações dos conhecimentos e notas fiscais." />
                            </a>
                        </div>
                        <div>
                            <a href="/faturavencimento">
                                <Card
                                    subtitulo=""
                                    titulo="Faturas e vencimentos"
                                    classNameIcone="fas fa-money-check-alt"
                                    texto="Consulta das informações das faturas e vencimentos." />
                            </a>
                        </div>
                        <div>
                            <a href="/cotacaopreco">
                                <Card
                                    subtitulo=""
                                    titulo="Cotação de preço de carga"
                                    classNameIcone="fas fa-search-dollar"
                                    texto="Consulta e criação de cotação de preço de carga." />
                            </a>
                        </div>
                    </div>
                </div>
                <ModalWarning
                    show={showWarning}
                    onHide={handleHide}
                    message={messageWarning} />
            </RxlibLayout>
        </>
    );
}

export default Home;
import "./template.css";
import "../../rxlib/style/rxlib.css";
import "../../rxlib/style/responsividade.css";
import { obterVersao } from "../../services/utilitarios";
import { obterAmbiente } from "../../rxlib/services/utilitarios";
import ButtonLink from "../../rxlib/components/buttons/button-link";

function Principal() {
    return (
        <>
            <div className="login-content d-flex align-items-center">
                <form className="form-signin mx-auto rxlib-login">
                    <div className="text-center mb-3">
                        <label className="rxlib-nome">CargasSac</label>
                    </div>
                    <div className="d-grid align-middle">
                        <ButtonLink
                            link="/login"
                            className="btn-lg"
                            classStyle="btn-rxlib"
                            texto="Acessar portal do cliente" />
                    </div>
                    <div className="d-grid align-middle mt-2">
                        <ButtonLink
                            className="btn-lg"
                            link="/consultapublica"
                            classStyle="btn-rxlib"
                            texto="Consultar nota fiscal" />
                    </div>
                    <p className="mt-3 mb-0 text-muted text-center">
                        © 2024 {obterVersao()}
                        <span className="rxlib-homologacao">
                            {obterAmbiente()}
                        </span>
                    </p>
                </form>
            </div>
        </>
    );
}

export default Principal;
/* rxlib - RxlibLayout v2.0.0 */

import { useState } from "react";
import { ptBR } from "@mui/material/locale";
import { Redirect } from "react-router-dom";
import { NavbarLayout } from "../navbar-layout";
import { SidebarLayout } from "../sidebar-layout";
import { useAppSelector } from "../../../../store/hooks";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import {
    montarMenus,
    NomeEmpresa,
    UsarSidebarLayout,
    UsarSidebarBodyGradient,
    ExibirNomeEmpresaNavbarLayout,
} from "../../../../services/config";

interface Subitem {
    nome: string;
    link: string;
}

export interface Item {
    nome: string;
    subItens: Subitem[];
}

export interface Menu {
    items: Item[];
}

interface RxlibLayoutProps {
    children: React.ReactNode;
}

export function RxlibLayout(props: RxlibLayoutProps) {
    const [token] = useState<string>(useAppSelector(state => state.token));
    const [logado] = useState<boolean>(useAppSelector(state => state.logado));
    const [administrador] = useState<boolean>(useAppSelector(state => state.administrador));

    const theme = createTheme(
        {
            palette: {
                primary: {
                    light: "#4ECBCB",
                    main: "#01B4B8",
                    dark: "#008D8D",
                },
                secondary: {
                    light: "#4ECBCB",
                    main: "#01B4B8",
                    dark: "#008D8D",
                },
            },
            typography: {
                fontFamily: [
                    "system-ui",
                    "-apple-system",
                    "Segoe UI",
                    "Roboto",
                    "Helvetica Neue",
                    "Arial",
                    "Noto Sans",
                    "Liberation Sans",
                    "sans-serif",
                    "Apple Color Emoji",
                    "Segoe UI Emoji",
                    "Segoe UI Symbol",
                    "Noto Color Emoji"
                ].join(","),
            },
        },
        ptBR,
    );

    return (
        <ThemeProvider theme={theme}>
            {
                ((!logado) && (!token))
                    ? <Redirect to="/login/acessobloqueado" />
                    : ""
            }
            {
                (useAppSelector(state => state.expirado))
                    ? <Redirect to="/login" />
                    : ""
            }
            {
                UsarSidebarLayout
                    ? <SidebarLayout
                        nomeEmpresa={NomeEmpresa}
                        menu={montarMenus(token, administrador)}
                        usarSidebarBodyGradient={UsarSidebarBodyGradient}>
                        {props.children}
                    </SidebarLayout>
                    : <NavbarLayout
                        nomeEmpresa={NomeEmpresa}
                        menu={montarMenus(token, administrador)}
                        exibirNomeEmpresa={ExibirNomeEmpresaNavbarLayout}>
                        {props.children}
                    </NavbarLayout>
            }
        </ThemeProvider>
    );
}
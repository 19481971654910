import { useState } from "react";
import { Redirect } from "react-router";
import { useForm, SubmitHandler } from "react-hook-form";
import Button from "../../rxlib/components/buttons/button";
import { obterAmbiente } from "../../rxlib/services/utilitarios";
import { apenasNumeros, obterVersao } from "../../services/utilitarios";
import { ModalWarning } from "../../rxlib/components/modal/modal-warning";
import { ConsultaPublicaProps, FormConsultaPublica } from "../../services/tipos";

function ConsultaPublica(props: ConsultaPublicaProps) {
    const [acao, setAcao] = useState(props.match.params.acao);
    const [carregando, setCarregando] = useState<boolean>(false);
    const [pesquisando, setPesquisando] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [messageWarning, setMessageWarning] = useState<string[]>([]);

    const [dadosConsultaPublica, setDadosConsultaPublica] = useState<FormConsultaPublica>({
        documento: "",
        numeroNota: "",
    });

    const { register, handleSubmit } = useForm<FormConsultaPublica>();

    const handleHide = () => setShowWarning(false);

    const onSubmit: SubmitHandler<FormConsultaPublica> = data => {
        setCarregando(true);
        data.documento = apenasNumeros(data.documento);
        data.numeroNota = apenasNumeros(data.numeroNota);
        setDadosConsultaPublica(data);
        setPesquisando(true);
    }

    function showNaoLocalizado() {
        setMessageWarning(["A nota fiscal não foi localizada."]);
        setShowWarning(true);
        setAcao("");
    }

    function showNaoRalizado() {
        setMessageWarning(["Não foi possível realizar a consulta."]);
        setShowWarning(true);
        setAcao("");
    }

    return (
        <>
            {
                pesquisando
                    ? <Redirect to={`/posicaocarga/${dadosConsultaPublica.documento}/${dadosConsultaPublica.numeroNota}`} />
                    : ""
            }
            {
                acao === "naolocalizado"
                    ? showNaoLocalizado()
                    : ""
            }
            {
                acao === "naorealizado"
                    ? showNaoRalizado()
                    : ""
            }
            <div className="login-content d-flex align-items-center">
                <form className="form-signin mx-auto rxlib-login" onSubmit={handleSubmit(onSubmit)}>
                    <div className="text-center mb-3">
                        <label className="rxlib-nome">CargasSac</label>
                    </div>
                    <div className="form-label-group">
                        <input ref={register({ required: true })} name="documento" type="text" id="inputDocumento" className="form-control" placeholder="CPF / CNPJ" required autoFocus />
                        <label htmlFor="inputDocumento">CPF / CNPJ</label>
                    </div>
                    <div className="form-label-group">
                        <input ref={register({ required: true })} name="numeroNota" type="text" id="inputNumeroNota" className="form-control" placeholder="Número da Nota Fiscal" required />
                        <label htmlFor="inputNumeroNota">Número da Nota Fiscal</label>
                    </div>
                    <div className="d-grid align-middle">
                        <Button
                            type="submit"
                            texto="Pesquisar"
                            className="btn-lg"
                            classStyle="btn-rxlib"
                            carregando={carregando}
                            classNameDivSpinner="login-spinner-button" />
                    </div>
                    <p className="mt-3 mb-0 text-muted text-center">
                        © 2024 {obterVersao()}
                        <span className="rxlib-homologacao">
                            {obterAmbiente()}
                        </span>
                    </p>
                </form>
            </div>
            <ModalWarning
                show={showWarning}
                onHide={handleHide}
                message={messageWarning} />
        </>
    );
}

export default ConsultaPublica;
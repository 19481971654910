export function obterVersao(): string {
    return "v3.0.1";
}

export function obterNomeEmpresa(): string {
    return "CargasSac";
}

export function formatarPorTipoPersonalizado(type: string, value: string): string {
    if (type) {
        switch (type) {
            case "statusCotacaoPreco":
                return formatarStatusCotacaoPreco(value);
            default:
                return value;
        }
    } else {
        return value;
    }
}

function formatarStatusCotacaoPreco(value: string): string {
    switch (value) {
        case "B":
            return "Baixada";
        case "C":
            return "Cancelada";
        case "F":
            return "Confirmada";
        case "AS":
            return "Aguardando sincronização";
        default:
            return "A Confirmar";
    }
}

export function apenasNumeros(value: string): string {
    return value.replace(/[^0-9]/g, '');
}
import "./cadastro.css";
import { useState } from "react";
import api from "../../../services/api";
import React, { useEffect } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { useAppSelector } from "../../../store/hooks";
import { useForm, SubmitHandler } from "react-hook-form";
import Checkbox from "../../../rxlib/components/checkbox";
import InputLabel from "../../../rxlib/components/input-label";
import Redirecionar from "../../../rxlib/components/redirecionar";
import TextareaLabel from "../../../rxlib/components/textarea-label";
import ButtonsCrud from "../../../rxlib/components/buttons/buttons-crud";
import { RxlibLayout } from "../../../rxlib/components/layout/rxlib-Layout";
import { ModalWarning } from "../../../rxlib/components/modal/modal-warning";
import { ModalPrimary } from "../../../rxlib/components/modal/modal-primary";
import { SelectLabelUf } from "../../../rxlib/components/select/select-label-uf";
import Breadcrumb, { BreadcrumbItem } from "../../../rxlib/components/breadcrumb";
import { ApiError, CotacaoPreco, CotacaoPrecoForm, Cubagem } from "../../../services/tipos";
import { FiltroPesonalizado, SelectLabelAsync } from "../../../rxlib/components/select/select-label-async";
import { formatarCamposPorTipo, obterDataAtualJson, tratarErroApi } from "../../../rxlib/services/utilitarios";

function CotacaoPrecoCadastro() {
    const [salvo, setSalvo] = useState<boolean>(false);
    const [idEmpresa, setIdEmpresa] = useState<string>("");
    const [idRemetente, setIdRemetente] = useState<string>("");
    const [nomeContato, setNomeContato] = useState<string>("");
    const [carregando, setCarregando] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [showPrimary, setShowPrimary] = useState<boolean>(false);
    const [ufCidadeOrigem, setUfCidadeOrigem] = useState<string>("");
    const [idCidadeOrigem, setIdCidadeOrigem] = useState<string>("");
    const [messagePrimary, setMessagePrimary] = useState<string[]>([]);
    const [idCidadeDestino, setIdCidadeDestino] = useState<string>("");
    const [ufCidadeDestino, setUfCidadeDestino] = useState<string>("");
    const [messageWarning, setMessageWarning] = useState<string[]>([]);
    const [tenantId] = useState(useAppSelector(state => state.tenantId));

    const { register, handleSubmit } = useForm<CotacaoPrecoForm>();

    const handleHideWarning = () => setShowWarning(false);

    const handleHidePrimary = () => {
        setShowPrimary(false);
        setSalvo(true);
    }

    const breadcrumbs: BreadcrumbItem[] = [
        { texto: "Home", link: "/home" },
        { texto: "Cotação de preço de carga", link: "/cotacaopreco" },
        { texto: "Novo", link: "" },
    ];

    useEffect(() => {
        setCarregando(true);
        api.get("/Empresa/PodemEmitirDocumentosClientesVinculadosUsuario")
            .then(response => {
                if (response.data.length === 1) {
                    setIdEmpresa(response.data[0].id);
                }
                setCarregando(false);
            }).catch((error: AxiosError<ApiError>) => {
                setCarregando(false);
                setMessageWarning(tratarErroApi(error, "Não foi possível realizar a consulta: "));
                setShowWarning(true);
            });
    }, []);

    useEffect(() => {
        setCarregando(true);
        api.get("/Cliente/VinculadosUsuario")
            .then(response => {
                if (response.data.length === 1) {
                    setIdRemetente(response.data[0].id);
                }
                setCarregando(false);
            }).catch((error: AxiosError<ApiError>) => {
                setCarregando(false);
                setMessageWarning(tratarErroApi(error, "Não foi possível realizar a consulta: "));
                setShowWarning(true);
            });
    }, []);

    const onSubmit: SubmitHandler<CotacaoPrecoForm> = data => {
        setCarregando(true);
        api.get(`/OData/Cliente?$filter=id eq ${data.idRemetente}`)
            .then(response => {
                formatarCamposPorTipo<CotacaoPrecoForm>(data, getCotacaoPrecoFormBase());

                let cubagens: Cubagem[] = [];
                addCubagens();

                let cotacaoPreco: CotacaoPreco = {
                    status: "AS",
                    coletar: true,
                    entregar: true,
                    tipoFrete: "C",
                    peso: data.peso,
                    valor: data.valor,
                    cubagens: cubagens,
                    volumes: data.volumes,
                    idLicenciada: tenantId,
                    idEmpresa: data.idEmpresa,
                    idServico: data.idServico,
                    observacao: data.observacao,
                    nomeContato: data.nomeContato,
                    idRemetente: data.idRemetente,
                    idCidadeOrigem: data.idCidadeOrigem,
                    tipoTabela: getTipoTabela(response),
                    data: obterDataAtualJson(new Date()),
                    idCidadeDestino: data.idCidadeDestino,
                    telefoneContato: data.telefoneContato,
                    contribuinteIcms: data.contribuinteIcms,
                };

                api.post("/CotacaoPreco", cotacaoPreco)
                    .then(response => {
                        informarSucesso();
                    }).catch((error: AxiosError<ApiError>) => {
                        tratarErro(error);
                    });

                function addCubagens() {
                    AddCubagem(data.altura01, data.largura01, data.comprimento01, data.quantidadeVolumes01);
                    AddCubagem(data.altura02, data.largura02, data.comprimento02, data.quantidadeVolumes02);
                    AddCubagem(data.altura03, data.largura03, data.comprimento03, data.quantidadeVolumes03);
                    AddCubagem(data.altura04, data.largura04, data.comprimento04, data.quantidadeVolumes04);
                    AddCubagem(data.altura05, data.largura05, data.comprimento05, data.quantidadeVolumes05);
                }

                function AddCubagem(altura: number, largura: number, comprimento: number, quantidadeVolumes: number) {
                    if ((altura > 0) || (largura > 0) || (comprimento > 0) || (quantidadeVolumes > 0)) {
                        cubagens.push({
                            altura: altura,
                            largura: largura,
                            comprimento: comprimento,
                            volumes: quantidadeVolumes,
                        });
                    }
                }

                function getTipoTabela(response: AxiosResponse<any>): string {
                    return response.data.value[0].TabelaPreferencial !== "" ? response.data.value[0].TabelaPreferencial : "N";
                }

                function getCotacaoPrecoFormBase(): CotacaoPrecoForm {
                    return {
                        peso: 0,
                        data: "",
                        valor: 0,
                        status: "",
                        volumes: 0,
                        altura01: 0,
                        altura02: 0,
                        altura03: 0,
                        altura04: 0,
                        altura05: 0,
                        cubagens: [],
                        largura01: 0,
                        largura02: 0,
                        largura03: 0,
                        largura04: 0,
                        largura05: 0,
                        idEmpresa: "",
                        idServico: "",
                        tipoFrete: "",
                        coletar: false,
                        observacao: "",
                        tipoTabela: "",
                        entregar: false,
                        idRemetente: "",
                        nomeContato: "",
                        comprimento01: 0,
                        comprimento02: 0,
                        comprimento03: 0,
                        comprimento04: 0,
                        comprimento05: 0,
                        idLicenciada: "",
                        idCidadeOrigem: "",
                        idCidadeDestino: "",
                        telefoneContato: "",
                        quantidadeVolumes01: 0,
                        quantidadeVolumes02: 0,
                        quantidadeVolumes03: 0,
                        quantidadeVolumes04: 0,
                        quantidadeVolumes05: 0,
                        contribuinteIcms: false,
                    }
                }
            }).catch((error: AxiosError<ApiError>) => {
                setCarregando(false);
                setMessageWarning(tratarErroApi(error, "Não foi possível realizar a consulta: "));
                setShowWarning(true);
            });
    }

    function informarSucesso() {
        setMessagePrimary(["Cotação de preço de carga salva com sucesso."]);
        setShowPrimary(true);
    }

    function tratarErro(error: AxiosError<ApiError>) {
        setCarregando(false);
        setMessageWarning(tratarErroApi(error, "Não foi possível salvar a cotação de preço de carga: "));
        setShowWarning(true);
    }

    const filtroPesonalizadoCidadeOrigem: FiltroPesonalizado[] = [
        { nameField: "Uf", valueField: ufCidadeOrigem, valueDefault: "", tipo: "string" },
    ];

    const filtroPesonalizadoCidadeDestino: FiltroPesonalizado[] = [
        { nameField: "Uf", valueField: ufCidadeDestino, valueDefault: "", tipo: "string" },
    ];

    return (
        <>
            <RxlibLayout>
                <Breadcrumb
                    itens={breadcrumbs} />
                <form onSubmit={handleSubmit(onSubmit)} className="rxlib-form">
                    <div className="container-fluid">
                        <div className="row px-1">
                            <div className="col-12">
                                <h6>Nova cotação de preço de carga</h6>
                            </div>
                        </div>
                        <div className="row px-1">
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="container-filtro-cotacao-preco">
                                            <div className="titulo-grupo">
                                                Origem:
                                            </div>
                                            <div className="conteudo-grupo">
                                                <div className="row mt-2">
                                                    <div className="col-6">
                                                        <SelectLabelUf
                                                            action=""
                                                            foco="sim"
                                                            label="UF:"
                                                            required="sim"
                                                            name="ufOrigem"
                                                            id="inputUfOrigem"
                                                            valorSelecionado=""
                                                            ariaLabel="ufOrigem"
                                                            referencia={React.createRef()}
                                                            className="rxlib-select-label-coluna"
                                                            onChange={(e) => setUfCidadeOrigem(e.currentTarget.value)} />
                                                    </div>
                                                    <div className="col-6">
                                                        <SelectLabelAsync
                                                            foco="nao"
                                                            type="Cidade"
                                                            label="Cidade:"
                                                            name="idCidadeOrigem"
                                                            id="inputIdCidadeOrigem"
                                                            referencia={register({ required: true })}
                                                            onChangeValueSelected={setIdCidadeOrigem}
                                                            className="rxlib-select-label-async-coluna"
                                                            action={ufCidadeOrigem !== "" ? "" : "view"}
                                                            filtroPersonalizado={filtroPesonalizadoCidadeOrigem} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="container-filtro-cotacao-preco mt-2">
                                            <div className="titulo-grupo">
                                                Destino:
                                            </div>
                                            <div className="conteudo-grupo">
                                                <div className="row mt-2">
                                                    <div className="col-6">
                                                        <SelectLabelUf
                                                            foco="nao"
                                                            label="UF:"
                                                            required="sim"
                                                            name="ufDestino"
                                                            id="inputUfDestino"
                                                            valorSelecionado=""
                                                            ariaLabel="ufDestino"
                                                            referencia={React.createRef()}
                                                            className="rxlib-select-label-coluna"
                                                            action={idCidadeOrigem !== "" ? "" : "view"}
                                                            onChange={(e) => setUfCidadeDestino(e.currentTarget.value)} />
                                                    </div>
                                                    <div className="col-6">
                                                        <SelectLabelAsync
                                                            foco="nao"
                                                            type="Cidade"
                                                            label="Cidade:"
                                                            name="idCidadeDestino"
                                                            id="inputIdCidadeDestino"
                                                            referencia={register({ required: true })}
                                                            onChangeValueSelected={setIdCidadeDestino}
                                                            className="rxlib-select-label-async-coluna"
                                                            action={ufCidadeDestino !== "" ? "" : "view"}
                                                            filtroPersonalizado={filtroPesonalizadoCidadeDestino} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="container-filtro-cotacao-preco mt-2">
                                            <div className="titulo-grupo">
                                                Informações:
                                            </div>
                                            <div className="conteudo-grupo">
                                                <div className="row mt-2">
                                                    <div className="col-6">
                                                        <SelectLabelAsync
                                                            foco="nao"
                                                            type="Empresa"
                                                            label="Empresa:"
                                                            name="idEmpresa"
                                                            id="inputIdEmpresa"
                                                            valorSelecionado={idEmpresa}
                                                            referencia={register({ required: true })}
                                                            className="rxlib-select-label-async-coluna"
                                                            action={idCidadeDestino !== "" ? "" : "view"} />
                                                    </div>
                                                    <div className="col-6">
                                                        <SelectLabelAsync
                                                            foco="nao"
                                                            type="Cliente"
                                                            label="Remetente:"
                                                            name="idRemetente"
                                                            id="inputIdRemetente"
                                                            valorSelecionado={idRemetente}
                                                            referencia={register({ required: true })}
                                                            className="rxlib-select-label-async-coluna"
                                                            action={idCidadeDestino !== "" ? "" : "view"} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-4">
                                                        <SelectLabelAsync
                                                            foco="nao"
                                                            type="Servico"
                                                            label="Serviço:"
                                                            name="idServico"
                                                            id="inputIdServico"
                                                            referencia={register({ required: true })}
                                                            className="rxlib-select-label-async-coluna"
                                                            action={idCidadeDestino !== "" ? "" : "view"} />
                                                    </div>
                                                    <div className="col-4">
                                                        <InputLabel
                                                            type="text"
                                                            maxLength={50}
                                                            autoFocus={false}
                                                            name="nomeContato"
                                                            id="inputNomeContato"
                                                            label="Nome do contato:"
                                                            placeholder="Nome do contato"
                                                            className="rxlib-input-label-coluna"
                                                            referencia={register({ required: true })}
                                                            readOnly={idCidadeDestino !== "" ? false : true}
                                                            onChange={(e) => setNomeContato(e.currentTarget.value)} />
                                                    </div>
                                                    <div className="col-4">
                                                        <InputLabel
                                                            type="text"
                                                            maxLength={50}
                                                            mask="telefone"
                                                            required={false}
                                                            autoFocus={false}
                                                            name="telefoneContato"
                                                            id="inputTelefoneContato"
                                                            label="Telefone do contato:"
                                                            placeholder="Telefone do contato"
                                                            className="rxlib-input-label-coluna"
                                                            referencia={register({ required: false })}
                                                            readOnly={idCidadeDestino !== "" ? false : true} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-12">
                                                        <TextareaLabel
                                                            rows={3}
                                                            defaultValue=""
                                                            required={false}
                                                            maxLength={2000}
                                                            autoFocus={false}
                                                            name="observacao"
                                                            label="Observação:"
                                                            id="inputObservacao"
                                                            placeholder="Observação"
                                                            className="rxlib-textarea-label-coluna"
                                                            referencia={register({ required: false })}
                                                            readOnly={idCidadeDestino !== "" ? false : true} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-12">
                                                        <Checkbox
                                                            value={String(false)}
                                                            defaultChecked={false}
                                                            name="contribuinteIcms"
                                                            text="Contribuinte de ICMS"
                                                            referencia={register({ required: false })}
                                                            disabled={idCidadeDestino !== "" ? false : true} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="container-filtro-cotacao-preco">
                                            <div className="titulo-grupo">
                                                Dados da nota fiscal:
                                            </div>
                                            <div className="conteudo-grupo">
                                                <div className="row mt-2">
                                                    <div className="col-4">
                                                        <InputLabel
                                                            type="text"
                                                            name="valor"
                                                            maxLength={14}
                                                            mask="currency"
                                                            id="inputValor"
                                                            defaultValue=""
                                                            autoFocus={false}
                                                            label="Valor (R$):"
                                                            placeholder="Valor da nota fiscal"
                                                            className="rxlib-input-label-coluna"
                                                            referencia={register({ required: true })}
                                                            readOnly={nomeContato !== "" ? false : true} />
                                                    </div>
                                                    <div className="col-4">
                                                        <InputLabel
                                                            type="text"
                                                            name="peso"
                                                            maxLength={14}
                                                            id="inputPeso"
                                                            mask="currency"
                                                            defaultValue=""
                                                            autoFocus={false}
                                                            label="Peso (Kg):"
                                                            placeholder="Peso da nota fiscal"
                                                            className="rxlib-input-label-coluna"
                                                            referencia={register({ required: true })}
                                                            readOnly={nomeContato !== "" ? false : true} />
                                                    </div>
                                                    <div className="col-4">
                                                        <InputLabel
                                                            type="text"
                                                            mask="number"
                                                            maxLength={14}
                                                            name="volumes"
                                                            defaultValue=""
                                                            autoFocus={false}
                                                            id="inputVolumes"
                                                            label="Quantidade de volumes:"
                                                            className="rxlib-input-label-coluna"
                                                            referencia={register({ required: true })}
                                                            readOnly={nomeContato !== "" ? false : true}
                                                            placeholder="Qtd. de volumes da nota fiscal" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="container-filtro-cotacao-preco mt-2">
                                            <div className="titulo-grupo">
                                                Medidas da mercadoria (Cubagem):
                                            </div>
                                            <div className="conteudo-grupo conteudo-grupo-medidas-mercadoria">
                                                <div className="row row-medidas-mercadoria mt-1">
                                                    <div className="col-12">
                                                        <table className="table table-bordered table-medidas-mercadoria">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Altura (cm)</td>
                                                                    <td>Largura (cm)</td>
                                                                    <td>Comprimento (cm)</td>
                                                                    <td>Volumes (Qtd)</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <InputLabel
                                                                            label=""
                                                                            type="text"
                                                                            placeholder=""
                                                                            maxLength={14}
                                                                            name="altura01"
                                                                            mask="currency"
                                                                            required={false}
                                                                            autoFocus={false}
                                                                            id="inputAltura01"
                                                                            defaultValue="0,00"
                                                                            className="rxlib-input-label-coluna"
                                                                            referencia={register({ required: false })}
                                                                            readOnly={nomeContato !== "" ? false : true} />
                                                                    </td>
                                                                    <td>
                                                                        <InputLabel
                                                                            label=""
                                                                            type="text"
                                                                            placeholder=""
                                                                            maxLength={14}
                                                                            mask="currency"
                                                                            required={false}
                                                                            name="largura01"
                                                                            autoFocus={false}
                                                                            id="inputLargura01"
                                                                            defaultValue="0,00"
                                                                            className="rxlib-input-label-coluna"
                                                                            referencia={register({ required: false })}
                                                                            readOnly={nomeContato !== "" ? false : true} />
                                                                    </td>
                                                                    <td>
                                                                        <InputLabel
                                                                            label=""
                                                                            type="text"
                                                                            placeholder=""
                                                                            maxLength={14}
                                                                            mask="currency"
                                                                            required={false}
                                                                            autoFocus={false}
                                                                            defaultValue="0,00"
                                                                            name="comprimento01"
                                                                            id="inputComprimento01"
                                                                            className="rxlib-input-label-coluna"
                                                                            referencia={register({ required: false })}
                                                                            readOnly={nomeContato !== "" ? false : true} />
                                                                    </td>
                                                                    <td>
                                                                        <InputLabel
                                                                            label=""
                                                                            type="text"
                                                                            mask="number"
                                                                            placeholder=""
                                                                            maxLength={14}
                                                                            defaultValue="0"
                                                                            autoFocus={false}
                                                                            name="quantidadeVolumes01"
                                                                            id="inputQuantidadeVolumes01"
                                                                            className="rxlib-input-label-coluna"
                                                                            referencia={register({ required: false })}
                                                                            readOnly={nomeContato !== "" ? false : true} />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <InputLabel
                                                                            label=""
                                                                            type="text"
                                                                            placeholder=""
                                                                            maxLength={14}
                                                                            name="altura02"
                                                                            mask="currency"
                                                                            required={false}
                                                                            autoFocus={false}
                                                                            id="inputAltura02"
                                                                            defaultValue="0,00"
                                                                            className="rxlib-input-label-coluna"
                                                                            referencia={register({ required: false })}
                                                                            readOnly={nomeContato !== "" ? false : true} />
                                                                    </td>
                                                                    <td>
                                                                        <InputLabel
                                                                            label=""
                                                                            type="text"
                                                                            placeholder=""
                                                                            maxLength={14}
                                                                            mask="currency"
                                                                            required={false}
                                                                            name="largura02"
                                                                            autoFocus={false}
                                                                            id="inputLargura02"
                                                                            defaultValue="0,00"
                                                                            className="rxlib-input-label-coluna"
                                                                            referencia={register({ required: false })}
                                                                            readOnly={nomeContato !== "" ? false : true} />
                                                                    </td>
                                                                    <td>
                                                                        <InputLabel
                                                                            label=""
                                                                            type="text"
                                                                            placeholder=""
                                                                            maxLength={14}
                                                                            mask="currency"
                                                                            required={false}
                                                                            autoFocus={false}
                                                                            defaultValue="0,00"
                                                                            name="comprimento02"
                                                                            id="inputComprimento02"
                                                                            className="rxlib-input-label-coluna"
                                                                            referencia={register({ required: false })}
                                                                            readOnly={nomeContato !== "" ? false : true} />
                                                                    </td>
                                                                    <td>
                                                                        <InputLabel
                                                                            label=""
                                                                            type="text"
                                                                            mask="number"
                                                                            placeholder=""
                                                                            maxLength={14}
                                                                            defaultValue="0"
                                                                            autoFocus={false}
                                                                            name="quantidadeVolumes02"
                                                                            id="inputQuantidadeVolumes02"
                                                                            className="rxlib-input-label-coluna"
                                                                            referencia={register({ required: false })}
                                                                            readOnly={nomeContato !== "" ? false : true} />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <InputLabel
                                                                            label=""
                                                                            type="text"
                                                                            placeholder=""
                                                                            maxLength={14}
                                                                            name="altura03"
                                                                            mask="currency"
                                                                            required={false}
                                                                            autoFocus={false}
                                                                            id="inputAltura03"
                                                                            defaultValue="0,00"
                                                                            className="rxlib-input-label-coluna"
                                                                            referencia={register({ required: false })}
                                                                            readOnly={nomeContato !== "" ? false : true} />
                                                                    </td>
                                                                    <td>
                                                                        <InputLabel
                                                                            label=""
                                                                            type="text"
                                                                            placeholder=""
                                                                            maxLength={14}
                                                                            mask="currency"
                                                                            required={false}
                                                                            name="largura03"
                                                                            autoFocus={false}
                                                                            id="inputLargura03"
                                                                            defaultValue="0,00"
                                                                            className="rxlib-input-label-coluna"
                                                                            referencia={register({ required: false })}
                                                                            readOnly={nomeContato !== "" ? false : true} />
                                                                    </td>
                                                                    <td>
                                                                        <InputLabel
                                                                            label=""
                                                                            type="text"
                                                                            placeholder=""
                                                                            maxLength={14}
                                                                            mask="currency"
                                                                            required={false}
                                                                            autoFocus={false}
                                                                            defaultValue="0,00"
                                                                            name="comprimento03"
                                                                            id="inputComprimento03"
                                                                            className="rxlib-input-label-coluna"
                                                                            referencia={register({ required: false })}
                                                                            readOnly={nomeContato !== "" ? false : true} />
                                                                    </td>
                                                                    <td>
                                                                        <InputLabel
                                                                            label=""
                                                                            type="text"
                                                                            mask="number"
                                                                            placeholder=""
                                                                            maxLength={14}
                                                                            defaultValue="0"
                                                                            autoFocus={false}
                                                                            name="quantidadeVolumes03"
                                                                            id="inputQuantidadeVolumes03"
                                                                            className="rxlib-input-label-coluna"
                                                                            referencia={register({ required: false })}
                                                                            readOnly={nomeContato !== "" ? false : true} />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <InputLabel
                                                                            label=""
                                                                            type="text"
                                                                            placeholder=""
                                                                            maxLength={14}
                                                                            name="altura04"
                                                                            mask="currency"
                                                                            required={false}
                                                                            autoFocus={false}
                                                                            id="inputAltura04"
                                                                            defaultValue="0,00"
                                                                            className="rxlib-input-label-coluna"
                                                                            referencia={register({ required: false })}
                                                                            readOnly={nomeContato !== "" ? false : true} />
                                                                    </td>
                                                                    <td>
                                                                        <InputLabel
                                                                            label=""
                                                                            type="text"
                                                                            placeholder=""
                                                                            maxLength={14}
                                                                            mask="currency"
                                                                            required={false}
                                                                            name="largura04"
                                                                            autoFocus={false}
                                                                            id="inputLargura04"
                                                                            defaultValue="0,00"
                                                                            className="rxlib-input-label-coluna"
                                                                            referencia={register({ required: false })}
                                                                            readOnly={nomeContato !== "" ? false : true} />
                                                                    </td>
                                                                    <td>
                                                                        <InputLabel
                                                                            label=""
                                                                            type="text"
                                                                            placeholder=""
                                                                            maxLength={14}
                                                                            mask="currency"
                                                                            required={false}
                                                                            autoFocus={false}
                                                                            defaultValue="0,00"
                                                                            name="comprimento04"
                                                                            id="inputComprimento04"
                                                                            className="rxlib-input-label-coluna"
                                                                            referencia={register({ required: false })}
                                                                            readOnly={nomeContato !== "" ? false : true} />
                                                                    </td>
                                                                    <td>
                                                                        <InputLabel
                                                                            label=""
                                                                            type="text"
                                                                            mask="number"
                                                                            placeholder=""
                                                                            maxLength={14}
                                                                            defaultValue="0"
                                                                            autoFocus={false}
                                                                            name="quantidadeVolumes04"
                                                                            id="inputQuantidadeVolumes04"
                                                                            className="rxlib-input-label-coluna"
                                                                            referencia={register({ required: false })}
                                                                            readOnly={nomeContato !== "" ? false : true} />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <InputLabel
                                                                            label=""
                                                                            type="text"
                                                                            placeholder=""
                                                                            maxLength={14}
                                                                            name="altura05"
                                                                            mask="currency"
                                                                            required={false}
                                                                            autoFocus={false}
                                                                            id="inputAltura05"
                                                                            defaultValue="0,00"
                                                                            className="rxlib-input-label-coluna"
                                                                            referencia={register({ required: false })}
                                                                            readOnly={nomeContato !== "" ? false : true} />
                                                                    </td>
                                                                    <td>
                                                                        <InputLabel
                                                                            label=""
                                                                            type="text"
                                                                            placeholder=""
                                                                            maxLength={14}
                                                                            mask="currency"
                                                                            required={false}
                                                                            name="largura05"
                                                                            autoFocus={false}
                                                                            id="inputLargura05"
                                                                            defaultValue="0,00"
                                                                            className="rxlib-input-label-coluna"
                                                                            referencia={register({ required: false })}
                                                                            readOnly={nomeContato !== "" ? false : true} />
                                                                    </td>
                                                                    <td>
                                                                        <InputLabel
                                                                            label=""
                                                                            type="text"
                                                                            placeholder=""
                                                                            maxLength={14}
                                                                            mask="currency"
                                                                            required={false}
                                                                            autoFocus={false}
                                                                            defaultValue="0,00"
                                                                            name="comprimento05"
                                                                            id="inputComprimento05"
                                                                            className="rxlib-input-label-coluna"
                                                                            referencia={register({ required: false })}
                                                                            readOnly={nomeContato !== "" ? false : true} />
                                                                    </td>
                                                                    <td>
                                                                        <InputLabel
                                                                            label=""
                                                                            type="text"
                                                                            mask="number"
                                                                            placeholder=""
                                                                            maxLength={14}
                                                                            defaultValue="0"
                                                                            autoFocus={false}
                                                                            name="quantidadeVolumes05"
                                                                            id="inputQuantidadeVolumes05"
                                                                            className="rxlib-input-label-coluna"
                                                                            referencia={register({ required: false })}
                                                                            readOnly={nomeContato !== "" ? false : true} />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ButtonsCrud
                            visualizar={false}
                            styleButton="btn-rxlib"
                            carregando={carregando}
                            linkCancelarVoltar="/cotacaopreco" />
                    </div>
                </form>
                <ModalWarning
                    show={showWarning}
                    message={messageWarning}
                    onHide={handleHideWarning} />
                <ModalPrimary
                    show={showPrimary}
                    message={messagePrimary}
                    onHide={handleHidePrimary} />
                <Redirecionar
                    se={salvo}
                    para="/cotacaopreco" />
            </RxlibLayout>
        </>
    );
}

export default CotacaoPrecoCadastro;
import Home from "./view/home";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Login from "./view/login";
import Principal from "./view/principal";
import PosicaoCarga from "./view/posicao-carga";
import PageNotFound from "./view/page-not-found";
import ConsultaPublica from "./view/consulta-publica";
import InformacaoCarga from "./view/informacao-carga";
import FaturaVencimento from "./view/fatura-vencimento";
import CotacaoPrecoListagem from "./view/cotacao-preco/listagem";
import CotacaoPrecoCadastro from "./view/cotacao-preco/cadastro";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Switch>
            <Route exact path="/" component={Principal} />

            <Route exact path="/login" component={Login} />
            <Route path="/login/:acao" component={Login} />

            <Route exact path="/home" component={Home} />
            <Route path="/home/:acao" component={Home} />

            <Route exact path="/consultapublica" component={ConsultaPublica} />
            <Route path="/consultapublica/:acao" component={ConsultaPublica} />

            <Route exact path="/informacaocarga" component={InformacaoCarga} />

            <Route exact path="/faturavencimento" component={FaturaVencimento} />

            <Route exact path="/cotacaopreco" component={CotacaoPrecoListagem} />
            <Route exact path="/cotacaopreco/novo" component={CotacaoPrecoCadastro} />

            <Route exact path="/posicaocarga/:idNota" component={PosicaoCarga} />
            <Route exact path="/posicaocarga/:documento/:numeroNota" component={PosicaoCarga} />

            <Route path="*" component={PageNotFound} />
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;